import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    data : {
      title : "Ifast | Home",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      ogUrl: ''
    }
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    data : {
      title : "Ifast | Home",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      ogUrl: ''
    }
  },
  {
    path: 'about-us',
    loadChildren: () => import('./pages/about-us/about-us.module').then( m => m.AboutUsPageModule),
    data : {
      title : "About Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'Ifast, Campaign , Prize, Win money'
    }
  },
  // {
  //   path: 'how-it-works',
  //   loadChildren: () => import('./pages/about-us/about-us.module').then( m => m.AboutUsPageModule),
  //   data : {
  //     title : "How It Works | Ifast",
  //     description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
  //     ogUrl: '/how-it-works'
  //   }
  // },
  {
    path: 'how-it-works',
    loadChildren: () => import('./pages/how-it-works/how-it-works.module').then( m => m.HowItWorksPageModule),
    data : {
      title : "How It Works | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      ogUrl: '/how-it-works'
    }
  },
  {
    path: 'login',
    loadChildren: () => import('./system/login/login.module').then( m => m.LoginPageModule),
    data : {
      title : "Login | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      ogUrl: '/login'
    }
  },
  {
    path: 'delete-account',
    loadChildren: () => import('./system/delete-account/delete-account.module').then( m => m.DeleteAccountPageModule),
    data : {
      title : "Delete Account | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      ogUrl: '/delete-account'
    }
  },

  {
    path: 'register',
    loadChildren: () => import('./system/register/register.module').then( m => m.RegisterPageModule),
    data : {
      title : "Register New Account | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      ogUrl: '/register'
    }
  },
  {
    path: 'account/personal-details',
    loadChildren: () => import('./account/personal-details/personal-details.module').then( m => m.PersonalDetailsPageModule),
    canActivate: [AuthGuardService],
    data : {
      title : "My Account | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      ogUrl: '/how-it-works'
    }
  },
  {
    path: 'account/levels',
    loadChildren: () => import('./account/levels/levels.module').then( m => m.LevelsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'account/i-points',
    loadChildren: () => import('./account/i-points/i-points.module').then( m => m.IPointsPageModule),
    canActivate: [AuthGuardService],
    data : {
      title : "Account Levels | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'Ifast, Campaign , Prize, Win money'
    }

  },
  {
    path: 'account/payment-options',
    loadChildren: () => import('./account/payment-options/payment-options.module').then( m => m.PaymentOptionsPageModule),
    canActivate: [AuthGuardService],
  
  },
  {
    path: 'account/invite-friend',
    loadChildren: () => import('./account/invite-friend/invite-friend.module').then( m => m.InviteFriendPageModule),
    canActivate: [AuthGuardService],
    data : {
      title : "Refer a Friend | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'Ifast, Campaign , Prize, Win money'
    }
  },
  {
    path: 'account/change-password',
    loadChildren: () => import('./account/change-password/change-password.module').then( m => m.ChangePasswordPageModule),
    canActivate: [AuthGuardService],
    data : {
      title : "Change Password | My Account | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'Ifast, Campaign , Prize, Win money'
    }
  },
  {
    path: 'account/active-tickets',
    loadChildren: () => import('./account/active-tickets/active-tickets.module').then( m => m.ActiveTicketsPageModule),
    canActivate: [AuthGuardService],
    data : {
      title : "Active Tickets | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'Ifast, Campaign , Prize, Win money'
    }
  },
  {
    path: 'account/wish-list',
    loadChildren: () => import('./account/wish-list/wish-list.module').then( m => m.WishListPageModule),
    canActivate: [AuthGuardService],
    data : {
      title : "My Wishlist | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'Ifast, Campaign , Prize, Win money'
    }
  },
  {
    path: 'campaigns/product-detail/:id',
    loadChildren: () => import('./pages/product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule),
    data : {
      title : "Contact Us | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'contact, Ifast'
    }
  },
  {
    path: 'charities',
    loadChildren: () => import('./pages/charities/charities.module').then( m => m.CharitiesPageModule),
    data : {
      title : "Charities | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'privacy-policy, Ifast'
    }
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/about-us/about-us.module').then( m => m.AboutUsPageModule),
    data : {
      title : "Privacy Policy | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'privacy-policy, Ifast'
    }
  },
  {
    path: 'user-agreement',
    loadChildren: () => import('./pages/about-us/about-us.module').then( m => m.AboutUsPageModule),
    data : {
      title : "User Agreement | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'Ifast, Campaign , Prize, Win money'
    }
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/about-us/about-us.module').then( m => m.AboutUsPageModule),
    data : {
      title : "Terms Of Use | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'Ifast, Campaign , Prize, Win money'
    }
  },
  {
    path: 'cart',
    loadChildren: () => import('./pages/cart/cart.module').then( m => m.CartPageModule),
    data : {
      title : "Cart | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'Ifast, Campaign , Prize, Win money'
    }
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then( m => m.FaqPageModule),
    data : {
      title : "FAQs | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'Ifast, Campaign , Prize, Win money'
    }
  },
  {
    path: 'confirm-mail',
    loadChildren: () => import('./pages/confirm-mail/confirm-mail.module').then( m => m.ConfirmMailPageModule),
    data : {
      title : "Confirm Email | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'Ifast, Campaign , Prize, Win money'
    }
  },
  {
    path: 'account/orders',
    loadChildren: () => import('./account/orders/orders.module').then( m => m.OrdersPageModule),
    canActivate: [AuthGuardService],
    data : {
      title : "My Orders | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'Ifast, Campaign , Prize, Win money'
    }
  },
  {
    path: 'winners',
    loadChildren: () => import('./pages/winners/winners.module').then( m => m.WinnersPageModule),
    data : {
      title : "Winners | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'Ifast, Campaign , Prize, Win money'
    }
  },
  {
    path: 'campaigns',
    loadChildren: () => import('./pages/campaigns/campaigns.module').then( m => m.CampaignsPageModule),
    data : {
      title : "Campaigns | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'Ifast, Campaign , Prize, Win money'
    }
  },
  {
    path: 'campaigns/:id',
    loadChildren: () => import('./pages/campaigns/campaigns.module').then( m => m.CampaignsPageModule),
    data : {
      title : "Campaigns | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'Ifast, Campaign , Prize, Win money'
    }
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then( m => m.ProductsPageModule),
    data : {
      title : "Products | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'Ifast, Campaign , Prize, Win money'
    }
  },
  {
    path: 'forgot',
    loadChildren: () => import('./system/forgot/forgot.module').then( m => m.ForgotPageModule),
    data : {
      title : "Forgot Password | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'Ifast, Campaign , Prize, Win money'
    }
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./system/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule),
    data : {
      title : "Reset Password | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'Ifast, Campaign , Prize, Win money'
    }
  },
  {
    path: 'account/address',
    loadChildren: () => import('./account/address/address.module').then( m => m.AddressPageModule),
    data : {
      title : "Saved Address | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'Ifast, Campaign , Prize, Win money'
    }
  },
  {
    path: 'not-found',
    loadChildren: () => import('./system/not-found/not-found.module').then( m => m.NotFoundPageModule),
    data : {
      title : "404 Not Found | Ifast",
      description:'An online shopping experience like no other. With every purchase, luxury prizes including cars, cash, jewellery and watches could be yours!',
      keywords: 'Ifast, Campaign , Prize, Win money'
    }
  },
  {
    path: '**',
    redirectTo : 'not-found',
    pathMatch : 'full'
  },
  
]; 

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
